export  interface IUser {
  firstname?: string;
  lastname?: string;
}

export interface TimeStamps {
  createdAt?: string;
  updatedAt?: string;
}

export interface IAccount {
  method?: string;
}

export interface ITransation extends TimeStamps{
  status?: string;
  vendingStatus?: string;
  meter?: IMeter;
  meterNumer?: number;
  amount?: number;
  kkiapayId?: string;
  transacFees?: string;
  bill?: IBill;
}
export const PaymentState = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const VendingState = {
  SUCCESS: 'success',
  FAILED: 'failed',
}

export const PaymentSource = {
  MOMO: 'MOBILE_MONEY',
  CARD: 'CARD'
}
export enum SOCKET_EVENTS {
  BACKOFFICE_RETRY_VENDING_SUCCESS = 'retry_vending_success',
  BACKOFFICE_RETRY_VENDING_FAILED = 'retry_vending_failed',
  JOB_VENDING_SUCCESS = 'job_vending_success',
  JOB_VENDING_FAILED = 'job_vending_failed',
  USER_ACCOUNT_STATE_CHANGE = 'user_account_state_change',
  VENDING_SUCCESS = 'vending_success',
  VENDING_FAILED = 'vending_failed' 
}

export interface IPagination {
  total: number;
  totalPage: number;
  perPage?: number;
  lastPage?: number;
  currentPage?: number;

}
export const EMPTY_TABLE_MESSAGE = {
  default: 'Aucune donnée disponible',
  search: 'Aucune donnée ne correspond à votre recherche',
  filter: 'Aucune donnée ne correspond au filtre'
}
export const UserAccountState = {
  ACTIVE: 'active',
  DISABLE: 'disable'
}
export interface IMeter extends TimeStamps{
  number?: number;
  owner?: string;
  familiarName?: string;
  user?: string;
}

export interface IRole {
  name: string;
  slug?: string;
  description?: string;
}

export interface IAdmin {
  firstName: string;
  lastName: string;
  email: string;
  invited_by?: any;
  userId?: string;
  user?: any;
}

export interface SendInvitation {
  invited_by: string;
  email: string;
  role: string;
}
export interface IBill extends TimeStamps{
  meterId?: string;
  meterNumer?: string;
  meterOwner?: string;
  userPhone?: string;
  meterFamiliarName?: string;
  amount?: string;
  code?: string;
  kwh?: string;
  fees?: string;
  refCode?: string;
  sbeeInvoice?: string;
  tva?: string;
  transacFees?: string;
  total?: string;
}

export interface IStat {
  NumbOfUserWhoMakeOneTransac: number;
  allUsersNumber: number;
  numberOfRecharge: number;
  subscribeByEmail: number;
  subscribeByPhone: number;
  totalKkiapayFees: number;
  totalRecharge: number;
  transacRechargeAvg: number;
}