









































interface PaginationComponentData {
  pagination: IPagination | null;
  windowStartPage: number;
  windowEndPage: number;
}
import { IPagination } from '@/interfaces';
import Vue from 'vue'
export default Vue.extend({
  props: {
    css:{
      type: Object,
      default(){
        return {
          wrapperClass: 'flex bg-white my-2 p-2',
          activeClass: 'bg-secondary-100 text-white',
          disabledClass: 'disabled',
          pageClass: 'text-black mx-2 flex justify-center items-center rounded-full w-5 text-base h-5',
          icons: {}
        }
      }
    },

    onEachSide: {
      type: Number,
      default: 2
    }
  },
  data(): PaginationComponentData {
    return {
      pagination: null,
      windowStartPage: 1,
      windowEndPage: 1,
    }
  },

  methods: {
    setPagination(paginationData: IPagination | null){
      if (paginationData !== null) {
        const pagination = {...this.defaultPaginagionData, ...paginationData}
        pagination.lastPage = pagination.lastPage  ? pagination.lastPage : pagination.totalPage
        // console.log('typeof lastPage ==> ', typeof pagination.lastPage);
        // console.log('pagination to set ==> ', pagination);
        
        this.pagination = pagination as IPagination | null;

        console.log('setPagination: ', this.pagination);
      }
      
    },

    loadPage(page: string | number){
      this.$emit('change-page', page)
    },

    isCurrentPage(page: number): boolean{
      
      if (this.pagination !== null) {
        return this.pagination.currentPage === page
      }
      return false
    },

    setWindowPageBorn(page: number, index: number){
      // console.log('index => ', index);
      
      if (index === 0) {
        this.windowStartPage = page
        console.log('windowStartPage: ', this.windowStartPage);
        
      }

      if (index === (this.onEachSide * 2)) {
        this.windowEndPage =  page
        console.log('windowEndPage: ', this.windowEndPage);
        
      }
    }

  },

  computed: {
    totalPage (): number {
      if (this.pagination !== null) {
        return  this.pagination.lastPage || this.$data.pagination.totalPage
      }
      return 0
    },

    isOnFirstPage(): boolean{
      return this.pagination === null ? false : this.pagination.currentPage === 1
    },

    isOnLastPage(): boolean{
      return this.pagination === null ? false : this.pagination.currentPage === this.pagination.lastPage
    },

    notEnoughPages(): boolean{
      return this.totalPage < (this.onEachSide * 2) + 4
    },

    windowSize(): number{
      return this.onEachSide * 2 + 1
    },

    windowStart(): number {
      if (this.pagination === null) {
        return 0
      } 
    
      if (this.pagination.currentPage as number <= this.onEachSide * 2) {
        console.log('windowStart1: ', 1);
        
        return 1
      }
      

      let start = 0;
      const currentPage = this.pagination.currentPage || 1;
      console.log('currentPage: ', currentPage);
      
      if (currentPage <= (this.totalPage - (this.onEachSide * 2 + 1))) {
        if (currentPage !== this.windowStartPage && currentPage !== this.windowEndPage) {
          start = this.windowStartPage
          console.log('windowStart2: ', start);
          return start
        } else if (currentPage === this.windowStartPage && currentPage !== 1) {
          start = currentPage - this.onEachSide
          console.log('windowStart3: ', start);
          return start
        }  else  {
          start = currentPage - this.onEachSide
          console.log('windowStart4: ', start);
          return start
        }
      }else {

        if (currentPage === this.windowStartPage && currentPage !== 1) {
          start = currentPage - (this.onEachSide)
          console.log('windowStart5: ', start);
          this.setWindowPageBorn(start, 0)
          return start
        }else {
          start = this.pagination.totalPage - this.onEachSide * 2 
          console.log('windowStart6: ', start);
          start
        }
      }
      
      return start
    },

    defaultPaginagionData(): IPagination{
      return {
        total: 0,
        currentPage: 1,
        totalPage: 1,
        perPage: 10
      }
    }

    
  }
})
