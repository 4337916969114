var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.columns.length > 0)?_c('div',{staticClass:"v-table"},[_c('table',{class:['table table-fixed w-full', _vm.vTableClass.tableClass]},[_c('thead',[_c('tr',[_vm._l((_vm.tableHeaderColumns),function(column,index){return [(column.visible)?[(_vm.isSpecialColumn(column.name))?[(_vm.specialColumnIs(column.name, '__slot'))?_c('th',{key:index,class:[
                  'v-table-th-' + _vm.extractName(column.name),
                  _vm.columnALignement(column),
                  column.titleClass
                ],style:({ width: column.width }),domProps:{"innerHTML":_vm._s(_vm.titleRender(column))}}):_vm._e()]:_c('th',{key:index,class:[column.titleClass, _vm.columnALignement(column)],style:({ width: column.width }),domProps:{"innerHTML":_vm._s(_vm.titleRender(column))}})]:_vm._e()]})],2)]),_c('tbody',[(!_vm.loading)?[_vm._l((_vm.tableBodyRowsData),function(rowData,l){return [_c('tr',{key:l,class:_vm.setRowClass(rowData, l)},[_vm._l((_vm.tableHeaderColumns),function(column,c){return [(column.visible)?[(_vm.isSpecialColumn(column.name))?[(_vm.specialColumnIs(column.name, '__slot'))?_c('td',{key:c,class:[_vm.columnALignement(column), column.dataClass]},[_vm._t(_vm.extractName(column.name),[_c('div',{domProps:{"innerHTML":_vm._s(
                          column.transform
                            ? column.transform(rowData, column)
                            : _vm.getObjectValue(rowData, _vm.extractName(column.name))
                        )}})],{"data":rowData,"rowData":rowData,"rowIndex":l})],2):_vm._e()]:_c('td',{key:c,class:[_vm.columnALignement(column), column.dataClass],domProps:{"innerHTML":_vm._s(
                    column.transform
                      ? column.transform(rowData, column)
                      : _vm.getObjectValue(rowData, column.name)
                  )}})]:_vm._e()]})],2),(_vm.useRowDetail)?[_c('transition',{key:l,attrs:{"name":"rowDetailTransition"}},[(_vm.isVisibleRowDetail(l))?_c('tr',{class:_vm.vTableClass.rowDetailClass,on:{"click":function($event){return _vm.onRowDetailClick(rowData, $event)}}},[_c('td',{attrs:{"colspan":_vm.visibleColumnLenght}},[_c(_vm.detailRowComponent,{tag:"component",attrs:{"fields":_vm.tableHeaderColumns,"rowData":rowData,"rowIndex":l}})],1)]):_vm._e()])]:_vm._e()]})]:_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.displayLoading)?[_c('tr',[_c('td',{attrs:{"colspan":_vm.visibleColumnLenght}},[_vm._t("loading",[_c('div',{staticClass:"flex justify-center py-6 text-lg"},[_c('div',{staticClass:"dots"},[_c('div'),_c('div'),_c('div')])])])],2)])]:(!_vm.displayLoading && _vm.tableData.length === 0)?[_c('tr',[_c('td',{attrs:{"colspan":_vm.visibleColumnLenght}},[_vm._t("no-data",[_c('div',{staticClass:"flex flex-col justify-center items-center py-4 text-lg"},[_c('img',{staticStyle:{"height":"300px","width":"300px"},attrs:{"src":require("@/assets/backoffice/img/data-empty.gif")}}),_c('div',{staticClass:"my-6",domProps:{"innerHTML":_vm._s(_vm.noData)}})])])],2)])]:_vm._e()],2)],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }