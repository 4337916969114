



































































































































interface THeader {
  name: string;
  center?: boolean;
  align?: string;
  title?: any;
  width?: string;
  visible?: boolean;
  titleClass?: string;
  dataClass?: string;
  transform?: any;
  callback?: any;
  component?: any;
}

import { IPagination } from "@/interfaces";
import Axios from "axios";
import Vue from "vue";
interface VTableComponentData {
  tableData: any[];
  tablePaginationData: IPagination | null;
  visibleRowDetails: number[];
  loading: boolean;
  noData: string;
  currentPage: number;
}
// import VTableLine from "./VTableLine.vue";
export default Vue.extend({
  // components: { VTableLine },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },

    data: {
      type: [Array, Object],
      default() {
        return [];
      }
    },

    transform: {
      type: Function,
      default(data: any) {
        return data;
      }
    },

    transformResponseData: {
      type: Function,
      default(data: any, response: any) {
        return data;
      }
    },

    loadOnApiMode: {
      type: Function,
      default(parameters: any) {
        const { url, method, options, request, data } = parameters;
        let makeRequest;
        if (method.toLowerCase() === "post") {
          makeRequest = request[method](url, data, options);
        } else if (method.toLowerCase() === "get") {
          makeRequest = request[method](url, options);
        }
        return makeRequest;
      }
    },

    apiMode: {
      type: Boolean,
      default: false
    },

    showLoader: {
      type: Boolean,
      default: true
    },

    dataPath: {
      type: String,
      default: "data"
    },

    paginationPath: {
      type: String,
      default: ""
    },

    perPage: {
        type: Number,
        default: 10
    },
    initialPage: {
      type: Number,
      default: 1
    },

    apiUrl: {
      type: String,
      default: ""
    },

    httpMethod: {
      type: String,
      default: "get"
    },

    httpRequest: {
      type: [Object, Function],
      default() {
        return Axios;
      }
    },

    httpOptions: {
      type: Object,
      default(){
        return {}
      }
    },

    httpData: {},

    queryParams:{
      type: [Object, Function],
      default(){
        return {
          // sort: 'sort',
          page: 'page',
          perPage: 'perPage'
        }
      }
    },

    appendParams:{
      type: Object,
      default(){
        return {}
      }
    },


    rowKey: {
      type: String,
      default: "id"
    },

    rowClass: {
      type: [String, Function],
      default() {
        return "hover:bg-Grey-95";
      }
    },

    detailRowComponent: {
      type: [String, Object, Function],
      default: undefined
    },

    css: {
      type: Object,
      default() {
        return {
          tableClass: 'bg-white'
        };
      }
    },

    emptyDataMessage: {
      type: String,
      default: "Aucune donnée actuellement disponible"
    }
  },

  data(): VTableComponentData {
    return {
      tableData: [],
      tablePaginationData: null,
      visibleRowDetails:[],
      loading: false,
      noData: this.emptyDataMessage,
      currentPage: this.initialPage,
    };
  },

  methods: {
    parseColumnFromString(column: string): THeader {
      const col: THeader = {
        ...this.defaultHeaderOptions,
        name: column,
        title: this.titleRender({ name: column })
      };

      return col;
    },

    parseColumnFromObject(column: any): THeader {
      const col: THeader = {
        ...this.defaultHeaderOptions,
        ...column
      };

      return col;
    },

    titleRender(column: THeader) {
      return this.getTitle(column);
    },

    getTitle(column: THeader) {
      if (typeof column.title === "function") {
        return column.title();
      }

      if (typeof column.title === "undefined") {
        const name: string = this.isSpecialColumn(column.name)
          ? this.extractName(column.name)
          : column.name;
        return name.indexOf(".") === -1
          ? name.charAt(0).toUpperCase() + name.substr(1)
          : name.replace(".", " ");
      }
      // console.log("column ", column);

      return column.title;
    },

    isSpecialColumn(name: string): boolean {
      const isSpecialColumn = name.substr(0, 2) === "__";
      // console.log("isSpecialColumn " + name, isSpecialColumn);

      return isSpecialColumn;
    },

    specialColumnIs(specialColumn: string, field: string) {
      return this.extractArg(specialColumn) === field;
    },

    extractArg(specialColumn: string): string {
      if (this.isSpecialColumn(specialColumn)) {
        return specialColumn.split(":")[0];
      }
      return specialColumn;
    },

    extractName(specialColumn: string): string {
      if (this.isSpecialColumn(specialColumn)) {
        return specialColumn.split(":")[1];
      }
      return specialColumn;
    },

    isVisibleRowDetail(rowKey: number): boolean {
      return this.visibleRowDetails.indexOf(rowKey) >= 0;
    },

    toggleRowDetail(rowKey: number) {
      if (this.isVisibleRowDetail(rowKey)) {
        this.hideRowDetail(rowKey);
      } else {
        this.showRowDetail(rowKey);
      }
    },

    showRowDetail(rowKey: number) {
      if (!this.isVisibleRowDetail(rowKey)) {
        this.visibleRowDetails.push(rowKey);
      }
    },

    hideRowDetail(rowKey: number) {
      if (this.isVisibleRowDetail(rowKey)) {
        const index = this.visibleRowDetails.indexOf(rowKey);
        this.visibleRowDetails.splice(index, 1);
      }
    },

    onRowDetailClick(row: any, event: any) {
      this.fireEvent("row-detail-click", row, event);
    },

    fireEvent(name: string, ...args: any) {
      console.log("name ", name);
      console.log("args ", args);

      this.$emit(name, args);
    },

    setRowClass(row: any, index: number) {
      return typeof this.rowClass === "function"
        ? this.rowClass(row, index)
        : this.rowClass;
    },

    getObjectValue(object: any, path: string, throwError= false) {
      path = path.trim();
      let value: any;
      if (!path) {
        return;
      }

      if (path.indexOf(".") === -1) {
        if (
          typeof object === "object" &&
          object[path] !== undefined &&
          object[path] !== null
        ) {
          value = object[path];
        } else {
          // console.log(` typeof object !== "object" ${typeof object !==
          //   "object"} \n
          // object[path] !== undefined ${object[path] !== undefined} \n
          // object[path] !== null ${object[path] !== null} \n
          // `);

          // console.warn(
          //   `VTable: object is not object or has no property ${path} for object \n`,
          //   object
          // );
          if (throwError) {
           console.error(`VTable: object is not object or has no property ${path} for object \n`,object)
          }
          return;
        }
      } else {
        const keys = path.split(".");
        value = object;
        keys.forEach((key, i) => {
          if (
            value !== null &&
            value !== undefined &&
            typeof value[key] !== undefined &&
            value[key] !== null
          ) {
            value = value[key];
          } else {
            const currentPath = keys.slice(0, i + 1).toString();
            if (throwError) {
              console.warn(
                `VTable: object is not defiened or has no property ${currentPath} for object \n`,
                object
              );
            }
            return;
          }
        });
      }
      // console.log("VTable column value____ ", value);

      return value;
    },

    columnALignement(column:  any) {
      let align = "";
      switch (column.align) {
        case "center":
          align = "text-center";
          break;
        case "left":
          align = "text-left";
          break;
        case "right":
          align = "text-right";
          break;
      }
      return align;
    },

    refresh() {
      console.log("refresh table ");

      this.visibleRowDetails = [];
      this.tableData = [];
      this.loadData();
    },

    loadData () {
      //
      if (!this.isApiMode) {
        this.loadOnDataMode(this.data);
      } else {
        if (!this.apiUrl) {
          console.warn("VTable: apiUrl not defined on apiMode ");
        }
        this.httpOptions['params'] = this.getParams(this.getDefaultQueryParams())
        const options = {
          url: this.apiUrl,
          method: this.httpMethod,
          data: this.httpData,
          options: this.httpOptions,
          request: this.httpRequest
        };

        this.loading = true;
        this.$emit("loading");
        this.loadOnApiMode(options)
          .then((response: any) => {
            this.$emit("load-success", response.data);
            // console.log("VTable: response : ", response);

              const responseData = this.transformResponseData(response.data, response)
              if (Array.isArray(responseData)) {
                this.tableData = this.transform(responseData);
                this.$emit('loaded')
              } else {
                const data = this.transform(responseData);
                this.tableData = this.getObjectValue(data, this.dataPath, true); //Throw warning in this cas
                if (this.paginationPath) {
                  this.tablePaginationData = this.makePagination(this.getObjectValue(data, this.paginationPath)); //Throw warning in this cas
                  console.log('loadOnApiMode --> tablePaginationData: ', this.tablePaginationData);

                }
                this.$nextTick(() => {
                  if (this.paginationPath) {
                    this.$emit('pagination-data', this.tablePaginationData)
                  }
                  this.$emit('loaded')
                })
              }
          })
          .catch((error: any) => {
            //
            // console.log('VTable: error : ',error);
            this.loadError(error);
          })
          .finally(() => {
            this.loading = false;
            // console.log('VTable: finally loading : ',this.loading);
          });
      }
    },

    getParams(params: any){
      for (const appendParam in this.appendParams) {
        params[appendParam] = this.appendParams[appendParam]
      }
      return params
    },

    getDefaultQueryParams(){
      let params: any = {}

      if (typeof this.queryParams === 'function') {
        params = this.queryParams(this.currentPage, this.perPage)
        return params && typeof params === 'object' ? params : {}
      }
      params[this.queryParams.page] = this.currentPage;
      params[this.queryParams.perPage] = this.perPage;

      return params
    },

    loadOnDataMode(responseData: any) {
      this.$emit('loading');

      if (Array.isArray(responseData)) {
        this.tableData = this.transform(responseData);
        this.$emit('loaded')
      } else {
        const data = this.transform(responseData);
        this.tableData = this.getObjectValue(data, this.dataPath, true); //Throw warning in this cas
        if (this.paginationPath) {
          this.tablePaginationData = this.makePagination(this.getObjectValue(data, this.paginationPath))
        }
      }
      this.$nextTick(() => {
        if (this.paginationPath) {
          this.$emit('pagination-data', this.tablePaginationData)
        }
        this.$emit('loaded')
      })
    },

    parseTableHeaderColumns(columnData: any[]){
      //
      const columns: any[] = [];
      if (columnData.length > 0) {
        columnData.forEach(column => {
          if (typeof column === "string") {
            columns.push(this.parseColumnFromString(column));
          }
          if (typeof column === "object") {
            columns.push(this.parseColumnFromObject(column));
          }
        });
      }
      // console.log("Table columns ", columns);

      return columns;
    },

    loadError(response: any) {
      this.noData = `<div class="text-error text-lg font-bold" >${response}</div>`;
      this.$emit("load-error", response);
      this.$emit("loaded");
    },

    setEmptyDataMessage(message: string){
      this.noData = message
    },

    changePage (page: number | string) {
      console.log('VTable: change page ', page);

      if (page === 'prev') {
        this.gotoPreviousPage()
      } else if (page === 'next') {
        this.gotoNextPage()
      } else {
        this.gotoPage(page as number)
      }
    },

    gotoPreviousPage(){
      if (this.currentPage > 1) {
        this.currentPage--
        this.loadData()
      }
    },

    gotoNextPage(){

      if (this.tablePaginationData !== null && this.tablePaginationData.lastPage) {
        if (this.currentPage < this.tablePaginationData.lastPage) {
          this.currentPage++
          this.loadData()
        }
      }else {
        console.warn('VTable: gotoNextPage tablePaginationData is possibly undefined');
      }
    },

    gotoPage(page: number){
      // console.log('tablePaginationData: ', this.tablePaginationData);

      if (this.tablePaginationData !== null && this.tablePaginationData.lastPage) {
        if (page != this.currentPage && (page > 0 && page <= this.tablePaginationData.lastPage)) {
          this.currentPage = page
          this.loadData()
        }
      }else {
        console.warn('VTable: gotoPage tablePaginationData is possibly undefined');
      }
    },

    makePagination(paginationData: IPagination | null): IPagination | null{
      if (paginationData === null || paginationData === undefined) {
        console.warn('VTable: pagination data is null');
        return null;
      }
      const pagination: IPagination = {
        total: paginationData.total || 0,
        totalPage: paginationData.totalPage || Math.ceil((paginationData.totalPage || 1) / (paginationData.perPage || 1)),
        lastPage: paginationData.totalPage || Math.ceil((paginationData.totalPage || 1) / (paginationData.perPage || 1)),
        perPage: paginationData.perPage || this.perPage,
        currentPage: paginationData.currentPage || this.currentPage
      }
      return pagination
    }
  },

  watch: {
    apiUrl(val) {
      if (this.apiMode) {
        this.loadData();
      }
    },

    apiMode(val, old) {
      if (val !== old) {
        this.loadData();
      }
    },

    data(val,old){
      console.log('VTable: data change');
      this.loadData()
    },
    emptyDataMessage(val: string){
      this.setEmptyDataMessage(val)
    }
  },

  computed: {
    tableHeaderColumns(): any[] {
      return this.parseTableHeaderColumns(this.columns);
    },

    useRowDetail() {
      // console.log("$parent ", this.$parent);
      // console.log("typeof detailRowComponent", typeof this.detailRowComponent);
      // console.log("useRowDetail", !!this.detailRowComponent);

      return !!this.detailRowComponent;
    },

    visibleColumnLenght(): number {
      const columns = this.tableHeaderColumns;
      return columns.reduce((acc: number, column: any) => (column.visible ? ++acc : acc), 0);
    },

    tableBodyRowsData() {
      // console.log("Table data ", this.tableData);

      return this.$data.tableData;
    },

    vTableClass(): any {
      return {
        ...this.css
      };
    },

    displayLoading() {
      return this.showLoader && this.$data.loading;
    },

    isApiMode() {
      return this.apiMode;
    },

    defaultHeaderOptions(): THeader {
      const THeaderOptions: THeader = {
        name: "",
        width: "auto",
        center: false,
        align: "left",
        visible: true,
        titleClass:
          "pl-4 py-4 bg-grey-lightest font-bold text-sm text-grey-dark border-b border-grey-light",
        dataClass: "pl-4"
      };

      return THeaderOptions;
    }
  },
  mounted() {
    this.loadData();
    // console.log("VTable: mounted___");
  }
});
